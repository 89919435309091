<!-- 外层 -->
<template>
  <router-view />
</template>

<script>
// import Header from "@/components/Header";
// import Footter from "@/components/Footter";

export default {
  components: {
    // Header,
    // Footter
  },
  methods: {},
};
</script>

<style scoped>
/* @import "./layout.css"; */
</style>
